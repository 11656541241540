var React = require("react");
var moment = require("moment");
var fieldManager = require("@managers/fieldManager");
var { reduceSampleDensity } = require("@managers/helpers");

export const WATER_INPUT_DATA = Object.freeze([
  "etc",
  "irrigation_precipitation",
  "wfr",
  "precipitation_h",
  "plan_irr",
]);
export const WATER_INPUT_COLORS = {
  past_plan_irr: "#5E70D1",
  future_plan_irr: "#5E70D1",
  plan_irr: "#5E70D1",
  wfr: "#CD91CD",
  precipitation_h: "#94C7F6",
  irrigation_precipitation: "#DD776F",
  etc: "#919DB4",
};

export var getWaterInputData = (selectedField, waterMeterDataKeys) => {
  let WaterInputDataObject = [];
  let curDateUnx = moment().unix();

  if (selectedField.historical_data) {
    WaterInputDataObject = loadData(
      waterMeterDataKeys,
      selectedField,
      curDateUnx,
    );
  }

  let maxYAxisVal = cleanAndFormatTheData(
    WaterInputDataObject,
    selectedField,
    curDateUnx,
  );

  let newWaterData = {};
  newWaterData["wfr"] = WaterInputDataObject["WFR"];
  newWaterData["precipitation_h"] = WaterInputDataObject["PRECIPITATION"];
  newWaterData["plan_irr"] =
    WaterInputDataObject["PAST PLANNED"] ||
    WaterInputDataObject["FUTURE PLANNED"]
      ? {
          past: WaterInputDataObject["PAST PLANNED"],
          future: WaterInputDataObject["FUTURE PLANNED"],
        }
      : undefined;
  newWaterData["irrigation_precipitation"] =
    WaterInputDataObject["COMBINED WATER INPUT"];
  newWaterData["etc"] = WaterInputDataObject["ETC"];

  return { newWaterData, maxYAxisVal };
};

const cleanAndFormatTheData = (
  WaterInputDataObject,
  selectedField,
  curDateUnx,
) => {
  let maxYAxisVal = 0;
  // 1. take all keys from all 6 lists and put them in one list of keys (dates)
  let datesKeys = [];
  Object.keys(WaterInputDataObject).forEach((waterInputType) => {
    if (WaterInputDataObject[waterInputType]) {
      datesKeys = datesKeys.concat(
        Object.keys(WaterInputDataObject[waterInputType].values),
      );
    }
  });

  // add current day ts date to datesKeys array
  datesKeys.push(curDateUnx);
  datesKeys.sort();
  let copy_of_dates = [...datesKeys];

  // avoid days without values for equal spaces between ticks in x Axis
  let day_unix = 60 * 60 * 24;
  for (let i = 1; i < copy_of_dates.length; i++) {
    let days_difference = Math.floor(
      (parseInt(datesKeys[i]) - parseInt(datesKeys[i - 1])) / day_unix,
    );
    if (days_difference > 0) {
      for (let j = 1; j < days_difference; j++) {
        datesKeys.push((parseInt(datesKeys[i - 1]) + day_unix * j).toString());
      }
    }
  }

  Object.keys(WaterInputDataObject).forEach((waterInputType) => {
    if (!WaterInputDataObject[waterInputType]) {
      return;
    }

    // 2. fill all none existing keys with value 0 - in order to get matching keys between all lists
    fillInMissingkeys(
      datesKeys,
      waterInputType,
      WaterInputDataObject[waterInputType],
      curDateUnx,
    );

    // 3. clean lists from out of season dates
    WaterInputDataObject[waterInputType] = ignoreOutOfSeasonSamples(
      WaterInputDataObject[waterInputType],
      selectedField,
    );

    // 4. converts the original samples into accumulated samples
    WaterInputDataObject[waterInputType] = accumulateSamples(
      WaterInputDataObject[waterInputType],
      selectedField.geo_data.time_zone,
    );

    // 5. reduces the density of the input sample data
    WaterInputDataObject[waterInputType] = reduceSampleDensity(
      WaterInputDataObject[waterInputType],
    );

    // 6. find the max y value by comparing the last samples
    if (
      WaterInputDataObject[waterInputType][
        WaterInputDataObject[waterInputType].length - 1
      ].value > maxYAxisVal
    ) {
      maxYAxisVal =
        WaterInputDataObject[waterInputType][
          WaterInputDataObject[waterInputType].length - 1
        ].value;
    }
  });

  return maxYAxisVal;
};

const loadData = (waterMeterDataKeys, selectedField, curDateUnx) => {
  let historical_data = selectedField.historical_data;
  let seasonStartDate = fieldManager
    .getCurrentSeasonStartDate(selectedField)
    .unix();
  let wfr,
    precipitation,
    plan_irr,
    plan_irr_past,
    plan_irr_future,
    irrigation_precipitation,
    etc = undefined;

  // load wfr values
  if (
    waterMeterDataKeys.includes("wfr") ||
    waterMeterDataKeys.includes("irrigation_precipitation")
  ) {
    wfr = historical_data["wfr_length"]
      ? JSON.parse(JSON.stringify(historical_data["wfr_length"]))
      : undefined;
  }

  // load precipitation values
  if (
    waterMeterDataKeys.includes("precipitation_h") ||
    waterMeterDataKeys.includes("irrigation_precipitation")
  ) {
    precipitation = historical_data["precipitation_h"]
      ? JSON.parse(JSON.stringify(historical_data["precipitation_h"]))
      : undefined;
  }

  // load plan-irrrigation values
  if (waterMeterDataKeys.includes("plan_irr")) {
    plan_irr = historical_data["plan_irr"]
      ? JSON.parse(JSON.stringify(historical_data["plan_irr"]))
      : undefined;
    if (plan_irr) {
      (plan_irr_past = { values: {} }), (plan_irr_future = { values: {} });
      let sum_past_plan = 0;
      Object.keys(plan_irr && plan_irr.values).forEach((x) => {
        if (seasonStartDate <= x) {
          if (x <= curDateUnx) {
            plan_irr_past["values"][x] =
              plan_irr["values"][x].value || plan_irr["values"][x];
            sum_past_plan += parseFloat(plan_irr_past["values"][x]);
          } else {
            plan_irr_future["values"][x] = plan_irr["values"][x];
          }
        }
      });
      plan_irr_past["values"][curDateUnx] = "0";
      plan_irr_future["values"][curDateUnx+1] = sum_past_plan.toString();
    }
  }

  // load etc values
  if (waterMeterDataKeys.includes("etc")) {
    etc = historical_data["etc"]
      ? JSON.parse(JSON.stringify(historical_data["etc"]))
      : undefined;
  }

  // load irrigation values
  if (waterMeterDataKeys.includes("irrigation_precipitation")) {
    irrigation_precipitation = {};
    let irrigation_precipitation_combined_values = {};
    if (wfr && wfr.values && precipitation && precipitation.values) {
      irrigation_precipitation_combined_values = Object.entries(
        wfr.values,
      ).reduce(
        (acc, [key, value]) =>
          // if key is already in wfr, add the values, otherwise, create new pair
          ({
            ...acc,
            [key]: ((acc[key] && acc[key].value) || 0) + value.value,
          }),
        { ...precipitation.values },
      );
    } else if (wfr && wfr.values) {
      irrigation_precipitation_combined_values = wfr.values;
    } else if (precipitation && precipitation.values) {
      irrigation_precipitation_combined_values = precipitation.values;
    }
    irrigation_precipitation.values = irrigation_precipitation_combined_values;

    // In case we load wfr or precipitation_h for the combined list only
    // we remove them from their independent lists
    if (!waterMeterDataKeys.includes("wfr")) {
      wfr = undefined;
    }
    if (!waterMeterDataKeys.includes("precipitation_h")) {
      precipitation = undefined;
    }
  }

  let WaterInputDataObject = {
    WFR: wfr,
    PRECIPITATION: precipitation,
    "PAST PLANNED": plan_irr_past,
    "FUTURE PLANNED": plan_irr_future,
    "COMBINED WATER INPUT": irrigation_precipitation,
    ETC: etc,
  };
  return WaterInputDataObject;
};

const fillInMissingkeys = (
  datesKeys,
  waterInputType,
  waterInputData,
  curDateUnx,
) => {
  for (let i = 0; i < datesKeys.length; i++) {
    // take dates datesbigger than today for future planned irrigation only
    if (
      !waterInputType === "FUTURE PLANNED" ||
      (datesKeys[i] <= curDateUnx && !(datesKeys[i] in waterInputData.values))
    ) {
      waterInputData.values[datesKeys[i]] = 0;
    }
  }
};

const ignoreOutOfSeasonSamples = (data, selectedField) => {
  if (data) {
    let cyclicSowDate = fieldManager.getCurrentSeasonStartDate(selectedField);
    let dataValues = data.values;
    let dataValuesAsArray = Object.entries(dataValues);
    let cyclicSowDateTimeStamp = cyclicSowDate.unix();
    let filteredDataValues = dataValuesAsArray.filter(
      ([key, value]) => key >= cyclicSowDateTimeStamp,
    );
    let filteredDataObject = Object.fromEntries(filteredDataValues);
    data.values = filteredDataObject;
    return data;
  }
};

const accumulateSamples = (sampels, timezone) => {
  let currentDataSet = sampels;
  let filteredItems = Object.keys(currentDataSet.values);
  let waterData = filteredItems.map((sampleKey, index) => {
    let currentSample =
      currentDataSet.values[sampleKey] &&
      currentDataSet.values[sampleKey].value;
    if (typeof currentSample !== "number") {
      currentSample = currentDataSet.values[sampleKey] || 0;
    }
    return {
      value: parseFloat(currentSample),
      date: moment.unix(sampleKey).tz(timezone),
      index: index,
    };
  });

  // first for season start
  let res = [{ value: 0, date: waterData[0] && waterData[0].date, index: 0 }];
  if (!waterData) {
    return res;
  }
  for (let i = 0; i < waterData.length; i++) {
    res.push({
      value: waterData[i].value + res[i].value,
      date: waterData[i].date,
      index: waterData[i].index + 1,
    });
  }
  return res;
};
